<template>
  <div>
    <eden-page-header
      :title="'Cleaner Directory'"
      :subtitle="'Edit Cleaner Profile'"
    />
    <cleaner-form v-if="cleaner.id" :action="'edit'" :cleaner="cleaner" />
  </div>
</template>

<script>
import CleanerForm from "@/components/Logistics/Directory/Cleaners/CleanerForm.vue";

import * as actions from "@/store/action-types";
import cleaners from "@/requests/logistics/directory/cleaners";

export default {
  name: "CleanerEdit",
  components: { CleanerForm },
  data() {
    return {
      cleaner: {},
    };
  },
  computed: {
    cleanerId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
    this.getCleaner();
  },
  methods: {
    getCleaner() {
      cleaners
        .get(this.cleanerId)
        .then((response) => {
          if (response.data.status) {
            this.cleaner = response.data.data[0];
            this.cleaner.location_area_id =
              this.cleaner.location_area && this.cleaner.location_area.id;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
