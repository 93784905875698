<template>
  <div v-loading="loading" class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <eden-form-section>
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap mb-0">
            <el-col :md="24" class="is-flex is-flex-column is-align-center">
              <eden-image-upload :image-url.sync="form.avatar" :image-text="'avatar'"
                :image-folder="'logistics/cleaners'" />
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section title="Cleaner Details" subtitle="Tell us about this cleaner">
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="12">
              <el-form-item label="First name" prop="firstname" :rules="validateField()">
                <el-input type="text" v-model="form.firstname" placeholder="Enter first name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="Last name" prop="lastname" :rules="validateField()">
                <el-input type="text" v-model="form.lastname" placeholder="Enter last name"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="12">
              <el-form-item label="Phone Number" prop="phone_number" :rules="validatePhone()">
                <eden-phone-input :input.sync="form.phone_number" />
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="Alternate Phone Number (optional)" prop="alternative_phone">
                <eden-phone-input :input.sync="form.alternative_phone" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :md="24">
              <el-form-item label="Address" prop="address" :rules="validateField()">
                <el-input type="textarea" rows="4" v-model="form.address"
                  placeholder="Enter the cleaner's full address"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap" :gutter="15">
            <el-col :md="12">
              <el-form-item label="Location area (they live in)" prop="location_area_id" :rules="validateField()">
                <el-select v-model="form.location_area_id" filterable placeholder="Select city">
                  <el-option v-for="location in locationareas" :key="location.id" :label="location.name"
                    :value="location.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="Gender" prop="gender" :rules="validateField()">
                <el-select v-model="form.gender" placeholder="Select">
                  <el-option label="Male" value="male" />
                  <el-option label="Female" value="female" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select preferred working days" prop="preferred_working_days"
                :rules="validateField()">
                <el-checkbox-group v-model="form.preferred_working_days" class="eden-custom--radio flex"
                  @change="sortServiceDays">
                  <el-checkbox v-for="(value, index) in serviceDays()" :key="index" :label="value" class="small">{{
    value }}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <template v-for="(guarantor, index) in form.guarantors">
        <eden-form-section :key="index" :title="`Guarantor #${index + 1} Details`"
          subtitle="Put in the necessary details about this cleaner’s guarantor.">
          <template slot="form-fields">
            <el-row type="flex" :gutter="20" class="is-flex-wrap">
              <el-col :md="12">
                <el-form-item label="First name" :prop="'guarantors.' + index + '.firstname'" :rules="validateField()">
                  <el-input type="text" v-model="guarantor.firstname" placeholder="Enter first name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12">
                <el-form-item label="Last name" :prop="'guarantors.' + index + '.lastname'" :rules="validateField()">
                  <el-input type="text" v-model="guarantor.lastname" placeholder="Enter last name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="20" class="is-flex-wrap">
              <el-col :span="24">
                <el-form-item label="Phone Number" :prop="'guarantors.' + index + '.phone_number'"
                  :rules="validateField()">
                  <eden-phone-input :input.sync="guarantor.phone_number" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="is-flex-wrap">
              <el-col :md="24">
                <el-form-item label="Address" :prop="'guarantors.' + index + '.address'" :rules="validateField()">
                  <el-input type="textarea" rows="4" v-model="guarantor.address"
                    placeholder="Enter the guarantor's full address"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </eden-form-section>
      </template>
      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions">
            <el-button plain @click="$router.go(-1)">Cancel</el-button>
            <el-button v-if="action === 'add'" type="primary" :loading="adding" @click="add">Add Cleaner
            </el-button>
            <el-button v-else type="primary" :loading="updating" @click="update">Save changes
            </el-button>
          </div>
        </template>
      </eden-form-section>
    </el-form>
  </div>
</template>

<script>
import cleaners from "@/requests/logistics/directory/cleaners";
import guarantors from "@/requests/logistics/directory/guarantors";

export default {
  name: "CleanersForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    cleaner: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        avatar: "",
        firstname: "",
        lastname: "",
        phone_number: "",
        alternative_phone: "",
        address: "",
        location_area_id: "",
        gender: "",
        preferred_working_days: [],
        guarantors: [
          {
            firstname: "",
            lastname: "",
            phone_number: "",
            address: "",
          },
          {
            firstname: "",
            lastname: "",
            phone_number: "",
            address: "",
          },
        ],
      },
      adding: false,
      updating: false,
    };
  },
  computed: {
    locationareas() {
      return this.$store.getters.location_areas_list;
    },
  },
  created() {
    if (this.action === "edit") {
      this.loading = true;
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.cleaner[key];
      });
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  },
  watch: {
    'form.alternative_phone'(newVal) {
      if (newVal === '234' || newVal === '254') {
        this.form.alternative_phone = '';
      }
    },
  },
  methods: {
    sortServiceDays() {
      this.form.preferred_working_days = this.sortedDaysOfWeek(
        this.form.preferred_working_days
      );
    },
    cancel() {
      this.$router.push({
        name: "directory.index",
        query: { t: "cleaners" },
      });
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        cleaners
          .add(this.form)
          .then((response) => {
            const { status, data, message } = response.data;
            if (status) {
              this.saveGuarantors(data.id);
              this.$message.success(message);
              this.$router.push({
                name: "logistics.directory.index",
                query: { t: "cleaners" },
              });
            }
            this.adding = false;
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
    saveGuarantors(cleanerId) {
      const [guarantor_one, guarantor_two] = this.form.guarantors;
      let payload = {
        user_id: cleanerId,
        firstname: [guarantor_one.firstname, guarantor_two.firstname],
        lastname: [guarantor_one.lastname, guarantor_two.lastname],
        address: [guarantor_one.address, guarantor_two.address],
        phone_number: [guarantor_one.phone_number, guarantor_two.phone_number],
      };
      guarantors
        .add(payload)
        .then()
        .catch((error) => {
          this.adding = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        const payload = {
          cleaner_id: this.cleaner.id,
          ...this.form,
        };
        this.updating = true;
        cleaners
          .update(payload)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.updateGuarantors();
            } else {
              this.updating = false;
              this.$message.error(message);
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },

    updateGuarantors() {
      const [guarantor_one, guarantor_two] = this.form.guarantors;
      let payload = {
        user_id: this.cleaner.id,
        guarantor_id: [guarantor_one.id, guarantor_two.id],
        firstname: [guarantor_one.firstname, guarantor_two.firstname],
        lastname: [guarantor_one.lastname, guarantor_two.lastname],
        address: [guarantor_one.address, guarantor_two.address],
        phone_number: [guarantor_one.phone_number, guarantor_two.phone_number],
      };

      guarantors
        .update(payload)
        .then((response) => {
          const { status } = response.data;
          if (status) {
            this.$message.success("Cleaner profile updated!");
            this.$router.push({
              name: "logistics.directory.cleaner",
              params: { id: this.cleaner.id },
            });
          }
          this.updating = false;
        })
        .catch((error) => {
          this.updating = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-page-form {
  width: 80% !important;
}

.el-checkbox {
  height: 36px !important;
}
</style>
