import axios from "axios";

export default {
  add(payload) {
    return axios.post("crm/guarantor", payload);
  },

  update(payload) {
    return axios.post("crm/guarantor/update", payload);
  },
};
